<template>
  <div>
    <div class="footer footer-b45">
      <div class="container" style="padding: 0">
        <div class="col-md-6">
          <router-link class="" to="/"
            ><img
              class="img-responsive lw-logo"
              src="../../assets/images/little-wordsmiths-logo.png"
              alt="Little Wordsmiths - Children's Dictionary, Thesaurus and Encyclopedia"
          /></router-link>
        </div>
        <div class="col-md-6">
          <div
            role="menuitem"
            href="javascript:void(0)"
            style="padding: 15px; text-align: right"
          >
            <a
              target="_blank"
              class="lw-social-share col-1"
              href="https://www.facebook.com/littlewordsmiths/"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a
              target="_blank"
              class="lw-social-share col-1"
              href="https://www.instagram.com/littlewordsmiths/"
              ><i class="fab fa-instagram"></i
            ></a>
            <a
              target="_blank"
              class="lw-social-share col-1"
              href="https://twitter.com/lilwordsmiths"
              ><i class="fab fa-twitter"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer footer-b0">
      <router-link class="footer-link" to="/">Little Wordsmiths</router-link>
      <br class="mob-br" /><span class="no-mob">- </span>All Rights Reserved -
      Copyright 2022
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  props: {
    sectionType: String,
  },
  methods: {},
};
</script>
<style>
.footer-b45 {
  background: #ffffff;
  position: fixed;
  bottom: 25px;
  width: 100%;
}

.footer-b0 {
  background: #ffffff;
  border-top: 0.5px solid #333;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
</style>