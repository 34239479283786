<template>
<div></div>
  <!-- <div class="initial-loading"><span>Loading&#8230;</span></div> -->
</template>
<script>
export default {
  name: "Loader",
  data: () => {
    return {
      siteUrl: "https://fun.littlewordsmiths.com/API/",
    };
  },
  mounted() {},
  props: [],
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    // },
  },
  methods: {},
};
</script>
<style>
</style>