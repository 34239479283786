<template>
  <div>
    <Loader v-if="loader" />
    <div class="intro" style="background-color: #fff !important">
      <div class="intro-body">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <div class="brand-heading">
                What word would you like to learn about?
              </div>
              <p class="brand-sub-heading">
                We’re adding new words every week!
              </p>
              <div style="margin: 25px 0">
                <input
                  class="main-search"
                  placeholder="Type the word here ..."
                  type="text"
                  autocomplete="off"
                  v-on:keyup="searchWord"
                />
                <div
                  class="suggesstion-box s-h"
                  v-if="suggestionList.length > 0"
                >
                  <router-link
                    class="word-link"
                    :to="s.search_url"
                    v-for="s in suggestionList"
                    :key="s.ID"
                    v-html="s.search_html"
                    v-on:click="suggestionList = []"
                  >
                  </router-link>
                </div>
              </div>
              <p class="sub-text">
                Choose a
                <router-link class="txt-link" :to="randomWordLink">
                  <span>Random Word</span>
                </router-link>
                or
                <a
                  href="javascript:void(0)"
                  class="txt-link-sugg"
                  v-on:click="openLoginModal(0)"
                  >Suggest a Word</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section style="background-color: #60cbf2; padding: 4em 0 6em 0">
      <div class="container box2">
        <div class="box">
          <h2 class="brand-heading_w">How it works</h2>
          <h3 class="brand-sub-heading_w">
            What makes Little Wordsmiths different
          </h3>
        </div>
      </div>
      <div class="container" style="margin-top: 2%">
        <div class="row">
          <div class="col-md-6 col-sm-6 mar-b-30 feat-l">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/littlewordsmiths-g-rated-words.png"
                width="60"
              />
              <h4 class="title_b">G-Rated Words</h4>
              <p class="title">
                Little Wordsmiths provides a safe platform where parents can
                have peace of mind while children of all ages have fun while
                learning.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-r">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/easy-to-use.png"
                width="60"
              />
              <h4 class="title_b">Easy to use</h4>
              <p class="title">
                The easier it is to use our platform, the easier it is for
                children to learn. That is why one of our main objectives is to
                provide an easy to use platform that removes as many obstacles
                to your child’s learning as possible.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-l">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/no-ads.png"
                width="60"
              />
              <h4 class="title_b">No Ads</h4>
              <p class="title">
                We believe in providing an ad-free (distraction-free) platform
                that enables children to focus while having fun and learning.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-r">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/fun-and-interactive.png"
                width="60"
              />
              <h4 class="title_b">Fun and interactive</h4>
              <p class="title">
                Children want to have fun, plain and simple. Our platform will
                keep growing to ensure that we include as many features and
                ‘fun’ as possible.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-l">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/synced-with-app.png"
                width="60"
              />
              <h4 class="title_b">Synced with app</h4>
              <p class="title">
                Take Little Wordsmiths where ever you go. We’ve created a
                platform that stores your child’s progress and that will work
                any device. It’s easy to pick up where you left off and continue
                learning.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-r">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/guided-learning.png"
                width="60"
              />
              <h4 class="title_b">Guided learning</h4>
              <p class="title">
                We’ve incorporated many features that will help guide your child
                along to keep learning. A couple of those features are starring
                a word that has been read and bookmarking where your child left
                off.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-l">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/track-progress.png"
                width="60"
              />
              <h4 class="title_b">Track progress</h4>
              <p class="title">
                Parents and children can know exactly how many words have been
                read. This provides valuable information for parents and gives
                children goals to work towards.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 mar-b-30 feat-r">
            <div class="box_feat">
              <img
                class="lw_feat"
                src="../assets/images/parental-control.png"
                width="60"
              />
              <h4 class="title_b">Parental control</h4>
              <p class="title">
                Little Wordsmiths is a full dictionary, however, we only provide
                a baseline of words that are publicly available. We provide a
                parent dashboard where words can be turned ‘on and off’ to their
                discretion.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Loader from "@/components/includes/Loader";
export default {
  name: "HomePage",
  components: {
    Loader,
  },
  metaInfo() {
    return {
      title: this.tags.meta_title,
      titleTemplate: "%s | Little Wordsmiths",
      meta: [
        { name: "description", content: this.tags.meta_description },
        { name: "site_name", content: this.tags.meta_sitename },
        { name: "keywords", content: this.tags.meta_keywords },
        { property: "type", content: this.tags.meta_type },
        { property: "url", content: this.tags.meta_url },
        { property: "image", content: this.tags.meta_image },
        { name: "author", content: this.tags.meta_author },
        { property: "og:title", content: this.tags.meta_og_title },
        { property: "og:site_name", content: this.tags.meta_og_sitename },
        {
          property: "og:description",
          content: this.tags.meta_og_description,
        },
        { property: "og:type", content: this.tags.meta_og_type },
        { property: "og:url", content: this.tags.meta_og_url },
        { property: "og:image", content: this.tags.meta_og_image },
      ],
    };
  },
  data: () => {
    return {
      siteUrl: "https://fun.littlewordsmiths.com/API/",
      suggestionBoxVisible: false,
      suggestionList: [],
      randomWordLink: "",
      userId: 0,
      userName: "",
      loader: false,
      tags: {},
    };
  },
  mounted() {
    this.checkLogin();
  },
  props: ["sectionType", "fetchEvent"],
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    // },
    fetchEvent(dataObj) {
      this.checkLogin(dataObj);
    },
  },
  methods: {
    searchWord: function (event) {
      this.loader = true;
      this.suggestionList = [];
      let searchWord = event.target.value;
      this.suggestionBoxVisible = true;
      if (searchWord.length === 0) {
        this.suggestionBoxVisible = false;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          alphabet: "a",
          word: searchWord,
          userid: this.userId,
          token: this.userName,
        }),
      };
      fetch(this.siteUrl + "search.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loader = false;
          data.word.map((v, i) => {
            var res = v.word.charAt(0);
            var typ = v.type;
            var typ1 = typ.toLowerCase();
            var sugw = v.word;
            sugw = sugw.replace(
              searchWord,
              '<span class="col-1">' + searchWord + "</span>"
            );
            data.word[i]["search_url"] = `/` + res + `/` + v.word + `/`;
            data.word[i]["search_html"] =
              `<div>` +
              sugw +
              ` <span class='word-meta'>` +
              typ1 +
              `</span>` +
              `</div>`;
          });
          this.suggestionList = data.word;
        });
    },
    checkLogin: function (dataObj = {}) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.userId = user.id;
        this.userName = user.first_name + " " + user.last_name;
      }
      if (dataObj.type === "logout") {
        this.userId = "";
        this.userName = "";
      } else if (dataObj.type !== "bookmark") {
        this.getInitialData();
      }
    },
    openLoginModal: function (value) {
      if (this.userId > 0) {
        this.$emit("childToParent", { type: "suggest-word", value: value });
      } else {
        this.$emit("childToParent", { type: "login", value: value });
      }
    },
    getInitialData() {
      this.loader = true;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      fetch(this.siteUrl + "ajax_initial_information.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loader = false;
          this.tags = data.meta_data;
          this.randomWordLink = data.rlink;
        });
    },
  },
};
</script>