<template>
  <div>
    <Header
      sectionType="home"
      :fetchEvent="eventType"
      v-on:childToParent="getHeaderEvent"
    />
    <HomePage
      sectionType="home"
      v-on:childToParent="getHomePageEvent"
      :fetchEvent="eventType"
    />
    <Footer
      sectionType="home"
      v-on:childToParent="getFooterEvent"
      :fetchEvent="eventType"
    />
  </div>
</template>

<script>
import Header from "@/components/includes/Header.vue";
import HomePage from "@/components/HomePage.vue";
import Footer from "@/components/includes/Footer.vue";
export default {
  components: {
    Header,
    Footer,
    HomePage,
  },
  data: () => {
    return {
      eventType: "",
      userId: 0,
      userName: "",
    };
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    getHomePageEvent(dataObj) {
      let type = dataObj.type;
      let value = dataObj.value;
      this.eventType = { type, value };
    },
    getHeaderEvent(dataObj) {
      let type = dataObj.type;
      let value = dataObj.value;
      this.eventType = { type, value };
    },
    getFooterEvent(dataObj) {
      let type = dataObj.type;
      let value = dataObj.value;
      this.eventType = { type, value };
    },
    checkLogin: function () {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.userId = user.id;
        this.userName = user.first_name + " " + user.last_name;
      }
    },
  },
};
</script>