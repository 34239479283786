<template>
  <div class="col-md-12" style="overflow-x: auto">
    <div class="head-ltr" style="overflow: hidden">
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'a' } }"
        class="col-1"
        ><div class="ltr">Aa</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'b' } }"
        class="col-2"
        ><div class="ltr">Bb</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'c' } }"
        class="col-3"
        ><div class="ltr">Cc</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'd' } }"
        class="col-4"
        ><div class="ltr">Dd</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'e' } }"
        class="col-5"
        ><div class="ltr">Ee</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'f' } }"
        class="col-6"
        ><div class="ltr">Ff</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'g' } }"
        class="col-4"
        ><div class="ltr">Gg</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'h' } }"
        class="col-1"
        ><div class="ltr">Hh</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'i' } }"
        class="col-2"
        ><div class="ltr">Ii</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'j' } }"
        class="col-3"
        ><div class="ltr">Jj</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'k' } }"
        class="col-4"
        ><div class="ltr">Kk</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'l' } }"
        class="col-5"
        ><div class="ltr">Ll</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'm' } }"
        class="col-6"
        ><div class="ltr">Mm</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'n' } }"
        class="col-4"
        ><div class="ltr">Nn</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'o' } }"
        class="col-1"
        ><div class="ltr">Oo</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'p' } }"
        class="col-2"
        ><div class="ltr">Pp</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'q' } }"
        class="col-3"
        ><div class="ltr">Qq</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'r' } }"
        class="col-4"
        ><div class="ltr">Rr</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 's' } }"
        class="col-5"
        ><div class="ltr">Ss</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 't' } }"
        class="col-6"
        ><div class="ltr">Tt</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'u' } }"
        class="col-4"
        ><div class="ltr">Uu</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'v' } }"
        class="col-1"
        ><div class="ltr">Vv</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'w' } }"
        class="col-2"
        ><div class="ltr">Ww</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'x' } }"
        class="col-3"
        ><div class="ltr">Xx</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'y' } }"
        class="col-4"
        ><div class="ltr">Yy</div></router-link
      >
      <router-link
        :to="{ name: 'Search', params: { word: 1, letter: 'z' } }"
        class="col-5"
        ><div class="ltr">Zz</div></router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchNavLink",
};
</script>