<template>
  <div>
    <div
      v-bind:style="menuCalled != '' ? 'display: block;' : 'display: none;'"
      role="dialog"
      class="modal-backdrop"
    >
      <div class="modal-dialog modal_lw">
        <span aria-hidden="true" class="close" v-on:click="closePopup()"
          >&times;</span
        >
        <div class="panel panel-login">
          <div class="panel-heading">
            <div class="row">
              <div class="col-xs-6">
                <a
                  href="javascript:void(0)"
                  :class="menuCalled == 'login' ? 'active' : ''"
                  v-on:click="authLinkCalled('login')"
                  >Login</a
                >
              </div>
              <div class="col-xs-6">
                <a
                  href="javascript:void(0)"
                  :class="menuCalled == 'register' ? 'active' : ''"
                  v-on:click="authLinkCalled('register')"
                  >Register</a
                >
              </div>
            </div>
            <hr />
          </div>
          <div class="panel-body">
            <div
              v-show="submitted && (errorMsg != '' || successMsg != '')"
              :class="successMsg != '' ? 'valid-feedback' : 'invalid-feedback'"
            >
              {{ successMsg != "" ? successMsg : errorMsg }}
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div>
                  <form
                    v-if="menuCalled == 'login'"
                    @submit.prevent="handleLoginSubmit"
                    role="form"
                    v-bind:style="
                      menuCalled == 'login' ? 'display: block' : 'display: none'
                    "
                  >
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="emailAddress"
                        name="emailAddress"
                        tabindex="1"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        v-model="password"
                        name="password"
                        tabindex="2"
                        class="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6 col-sm-offset-3">
                          <input
                            type="submit"
                            name="login-submit"
                            tabindex="4"
                            class="form-control btn btn-login"
                            value="Login"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="text-center">
                            <a
                              href="javascript:void(0)"
                              v-on:click="authLinkCalled('forgot')"
                              class="forgot-password"
                              >Forgot Password?</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form
                    v-if="menuCalled == 'register'"
                    @submit.prevent="handleRegisterSubmit"
                    role="form"
                    v-bind:style="
                      menuCalled == 'register'
                        ? 'display: block'
                        : 'display: none'
                    "
                  >
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="fname"
                        name="fname"
                        tabindex="1"
                        class="form-control"
                        placeholder="First name"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="lname"
                        name="lname"
                        tabindex="2"
                        class="form-control"
                        placeholder="Last name"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        v-model="emailAddress"
                        name="emailAddress"
                        tabindex="3"
                        class="form-control"
                        placeholder="Email Address"
                      />
                    </div>
                    <div class="form-group">
                      <select
                        name="country"
                        v-model="country"
                        tabindex="4"
                        class="form-control country-cls"
                        placeholder="Country"
                      >
                        <option value="">Country</option>
                        <option value="1">Canada</option>
                        <option value="2">India</option>
                        <!-- <option v-bind:for="value in countries"
                          v-bind:value="value.value"
                        >
                          {{ value.name }}
                        </option> -->
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        name="password"
                        v-model="password"
                        tabindex="5"
                        class="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6 col-sm-offset-3">
                          <input
                            type="submit"
                            name="register-submit"
                            tabindex="4"
                            class="form-control btn btn-register"
                            value="Register Now"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <form
                    v-if="menuCalled == 'forgot'"
                    @submit.prevent="handleForgotSubmit"
                    role="form"
                    v-bind:style="
                      menuCalled == 'forgot'
                        ? 'display: block'
                        : 'display: none'
                    "
                  >
                    <div class="form-group">
                      <input
                        type="text"
                        name="emailAddress"
                        v-model="emailAddress"
                        tabindex="1"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6 col-sm-offset-3">
                          <input
                            type="submit"
                            name="login-submit"
                            tabindex="4"
                            class="form-control btn btn-login"
                            value="Send"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState, mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      siteUrl: "https://fun.littlewordsmiths.com/API/",
      fname: "",
      lname: "",
      country: "",
      emailAddress: "",
      password: "",
      submitted: false,
      error: false,
      countries: [],
      menuCalled: "",
      availModal: false,
      errorMsg: "",
      successMsg: "",
    };
  },
  mounted() {
    this.menuCalled = this.authCalled;
    this.getCountries();
  },
  computed: {
    // ...mapState("account", ["status"]),
  },
  props: ["authCalled"],
  methods: {
    // ...mapActions("account", ["login", "logout"]),
    authLinkCalled: function (type) {
      this.menuCalled = type;
    },
    closePopup: function () {
      this.$emit("childToParent", { type: "close", value: "" });
    },
    getCountries() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      fetch(this.siteUrl + "ajax_countries.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success == 1) {
            this.countries = data.data;
          } else {
            this.error = true;
          }
        });
    },
    handleLoginSubmit() {
      //e
      this.successMsg = "";
      this.errorMsg = "";
      this.submitted = true;
      this.error = false;
      const { emailAddress, password } = this;
      if (emailAddress && password) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: emailAddress,
            password: password,
          }),
        };
        fetch(this.siteUrl + "ajax_login.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success == 1) {
              localStorage.setItem("user", JSON.stringify(data.user));
              this.$emit("childToParent", { type: "loggedin", value: "" });
            } else {
              this.error = true;
              this.errorMsg = data.message;
            }
          });
      }
    },
    handleRegisterSubmit(e) {
      console.log(e);
      this.successMsg = "";
      this.errorMsg = "";
      this.submitted = true;
      const { fname, lname, emailAddress, country, password } = this;
      if (fname && lname && emailAddress && country && password) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            f_name: fname,
            l_name: lname,
            email: emailAddress,
            country: country,
            password: password,
          }),
        };
        fetch(this.siteUrl + "ajax_register.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success == 1) {
              this.successMsg =
                "Successfully registered. Please login to continue!";
              this.menuCalled = "login";
            } else {
              this.errorMsg = data.message;
            }
          });
      }
    },
    handleForgotSubmit(e) {
      console.log(e);
      this.successMsg = "";
      this.errorMsg = "";
      this.submitted = true;
      const { emailAddress } = this;
      if (emailAddress) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email_address: emailAddress,
          }),
        };
        fetch(this.siteUrl + "forgot-password.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success == 1) {
              this.successMsg = data.message; //"Successfully registered. Please login!"
            } else {
              this.errorMsg = data.message;
            }
          });
      }
    },
  },
};
</script>
<style>
.modal-backdrop {
  background-color: #00000073;
}
.modal_lw .close {
  font-size: 2em;
  color: #000;
  position: absolute;
  right: 5px;
  z-index: 1;
}
select.country-cls {
  padding: 6px 12px 6px 0px !important;
}
</style>