<template>
  <div>
    <router-view />
    <!-- <template v-slot:title="{ content }">{{ -->
    <!-- content ? `${content} | Little Wordsmiths` : `Little Wordsmiths` -->
    <!-- }}</template> -->
  </div>
</template>
<script>
export default {
  name: "app",
  watch: {
    // $route(to, from) {
    // console.log(to, from);
    // clear alert on location change
    //this.clearAlert();
    // },
  },
  mounted: () => {},
};
</script>