<template>
  <div>
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="nav-cont">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <router-link to="/" class="navbar-brand lrg-logo">
            <img
              class="img-responsive lw-logo"
              src="../../assets/images/little-wordsmiths-logo.png"
              alt="Little Wordsmiths - Children's Dictionary, Thesaurus and Encyclopedia"
            />
          </router-link>
          <div class="mob-signin pull-right">
            <div style="display: inline">
              <img
                id="mob-srch"
                class="search_icon pull-left"
                src="../../assets/images/littlewordsmiths-word-search-grey.png"
              />
            </div>
            <a
              id="user"
              class="col-6 user"
              href="javascript:void(0)"
              data-toggle="dropdown"
              ><span
                class="glyphicon glyphicon-user"
                aria-hidden="true"
                style="font-size: 22px"
              ></span
            ></a>
            <ul
              class="dropdown-menu dot-ul user-info"
              role="menu"
              aria-labelledby="menu2"
            >
              <li role="presentation">
                <a role="menuitem" tabindex="-1" href="javascript:void(0)"
                  >Hi, Firstname &nbsp; Lastname !</a
                >
              </li>
              <li role="presentation">
                <a
                  href="javascript:void(0)"
                  v-on:click="logoutUser()"
                  role="menuitem"
                  tabindex="-1"
                  >Logout</a
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <form class="navbar-form navbar-left">
            <!--  v-if="searchType == 'search'" -->
            <div id="menu-search">
              <div class="form-group" style="width: 83%; position: relative">
                <input
                  class="form-control m-s"
                  placeholder="What word do you want to learn about?"
                  type="text"
                  autocomplete="off"
                  v-on:keyup="searchWord"
                />
                <div
                  class="suggesstion-box s-h"
                  v-if="suggestionList.length > 0"
                >
                  <router-link
                    class="word-link"
                    :to="s.search_url"
                    v-for="s in suggestionList"
                    :key="s.ID"
                    v-html="s.search_html"
                    v-on:click="suggestionList = []"
                  >
                  </router-link>
                </div>
              </div>
              <img
                class="search_icon pull-left"
                src="../../assets/images/littlewordsmiths-word-search-grey.png"
              />
            </div>
          </form>
          <ul class="nav navbar-nav navbar-right">
            <li v-if="this.userId != ''">
              <a
                id="user"
                class="col-6 user"
                href="javascript:void(0)"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  class="glyphicon glyphicon-user"
                  aria-hidden="true"
                  style="font-size: 22px"
                ></span>
              </a>
              <ul
                class="dropdown-menu dot-ul user-info"
                role="menu"
                aria-labelledby="menu2"
              >
                <li role="presentation">
                  <a
                    href="javascript:void(0)"
                    role="menuitem"
                    tabindex="-1"
                    to="/mydashboard"
                    >Hi, {{ this.userName }}!</a
                  >
                </li>
                <li role="presentation">
                  <a
                    href="javascript:void(0)"
                    role="menuitem"
                    tabindex="-1"
                    v-on:click="logoutUser()"
                    >Logout</a
                  >
                </li>
              </ul>
            </li>
            <li v-if="this.userId == ''">
              <a
                class="col-6"
                href="javascript:void(0)"
                @click="authCalled('login')"
                >Sign In</a
              >
            </li>
            <li class="separator" v-if="this.userId == ''">&nbsp;</li>
            <li v-if="this.userId == ''">
              <a
                class="col-6"
                href="javascript:void(0)"
                @click="authCalled('register')"
                >Sign Up</a
              >
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
    <div id="srch-bar" class="mob-srch-bar" v-if="sectionType == 'search'">
      <form class="navbar-form-mob">
        <div id="menu-search-mob">
          <div class="form-group mob-grp">
            <input
              type="text"
              class="form-control m-s"
              placeholder="What word do you want to learn about?"
            />
          </div>
          <img
            class="search_icon pull-left"
            src="../../assets/images/littlewordsmiths-word-search-grey.png"
          />
          <img
            id="mob-srch-close"
            style="margin-right: 0; padding-right: 0"
            class="search_icon pull-right"
            src="../../assets/images/littlewordsmiths-word-search-grey.png"
          />
        </div>
      </form>
    </div>
    <div class="container page-cont letter-bar" v-if="sectionType == 'search'">
      <SearchNavLink />
    </div>
    <SuggestWord
      v-bind:suggestionCalled="openSuggestPopup"
      v-on:childToParent="openSuggestModal"
      v-if="openSuggestPopup != ''"
    />
    <!-- Modal -->
    <Login
      v-bind:authCalled="openPopupType"
      v-on:childToParent="getChildResponse"
      v-if="openPopupType != ''"
    />
    <!-- Modal END-->
  </div>
</template>
<script>
import Login from "@/components/Login";
import SuggestWord from "@/components/SuggestWord";
import SearchNavLink from "@/components/SearchNavLink";
export default {
  name: "Header",
  components: {
    Login,
    SuggestWord,
    SearchNavLink,
  },
  data: () => {
    return {
      siteUrl: "https://fun.littlewordsmiths.com/API/",
      suggestionBoxVisible: false,
      suggestionList: [],
      userId: 0,
      userName: "",
      openPopupType: "",
      openSuggestPopup: "",
    };
  },
  mounted() {
    this.checkLogin();
  },
  props: ["sectionType", "fetchEvent"],
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    // },
    fetchEvent(data) {
      let type = data.type;
      let value = data.value;
      if (type === "bookmark") {
        this.bookmarkWord(value);
      } else if (type === "suggest-word") {
        this.openSuggestPopup = type;
      } else {
        this.authCalled(type);
      }
    },
  },
  methods: {
    authCalled: function (type) {
      if (type === "close" || type === "loggedin" || type === "logout") {
        this.openPopupType = "";
      } else {
        this.openPopupType = type;
      }
    },
    bookmarkWord(dataObj) {
      let data = {
        wordid: dataObj.id,
        userid: this.userId,
      };
      var rem = "1";
      if (dataObj.class === "book-on") {
        data.remove = rem;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(this.siteUrl + "bookmark.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success == 1) {
            dataObj.type = "refresh";
            this.$emit("childToParent", dataObj);
          }
        });
    },
    openSuggestModal: function (dataObj) {
      let type = dataObj.type;
      if (type === "suggest-close") {
        this.openSuggestPopup = "";
      }
    },
    getChildResponse: function (dataObj) {
      let type = dataObj.type;
      // let value = dataObj.value;
      if (type === "close") {
        this.openPopupType = "";
        this.$emit("childToParent", dataObj);
      } else if (type === "loggedin") {
        this.checkLogin();
        this.openPopupType = "";
        this.$emit("childToParent", dataObj);
      } else if (type === "refresh") {
        this.checkLogin();
        this.openPopupType = "";
        this.$emit("childToParent", dataObj);
      } else if (type === "bookmark") {
        this.checkLogin();
        this.openPopupType = "";
        this.$emit("childToParent", dataObj);
      } else {
        this.checkLogin();
        this.openPopupType = "";
        this.$emit("childToParent", dataObj);
      }
      // document.getElementById("menu-search").style.display = "none";
    },
    checkLogin: function () {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.userId = user.id;
        this.userName = user.first_name + " " + user.last_name;
      }
    },
    logoutUser: function () {
      localStorage.removeItem("user");
      this.userId = "";
      this.userName = "";
      this.openPopupType = "";
      this.$emit("childToParent", { type: "logout", value: "" });
    },
    searchWord: function (event) {
      this.suggestionList = [];
      let searchWord = event.target.value;
      this.suggestionBoxVisible = true;
      if (searchWord.length === 0) {
        this.suggestionBoxVisible = false;
        // $('#suggesstion-box').css('background', '#FFF');
      }
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          word: searchWord,
          userid: this.userId,
          token: this.userId,
        }),
      };
      fetch(this.siteUrl + "search.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.word.map((v, i) => {
            var res = v.word.charAt(0);
            var typ = v.type;
            var typ1 = typ.toLowerCase();
            var sugw = v.word;
            sugw = sugw.replace(
              searchWord,
              '<span class="col-1">' + searchWord + "</span>"
            );
            data.word[i]["search_url"] = `/` + res + `/` + v.word + `/`;
            data.word[i]["search_html"] =
              `<div>` +
              sugw +
              ` <span class='word-meta'>` +
              typ1 +
              `</span>` +
              `</div>`;
          });
          this.suggestionList = data.word;
          // $("#suggesstion-box").css("background", "#FFF");
        }); //(this.postId = data.id)
    },
  },
};
</script>