import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes:
    [
      { path: "/", name: "Home", component: Home, },
      {
        path: '/:letter/:word',
        name: 'Search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Search.vue')
        }
      },
      {
        path: '/launch',
        name: 'Launch',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Launch.vue')
        }
      }
    ]
})

router.beforeEach((to, from, next) => {
  console.log(to)
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`
  next()
})

export default router
