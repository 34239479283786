<template>
  <div
    v-bind:style="suggestCalled != '' ? 'display: block;' : 'display: none;'"
    role="dialog"
    class="modal-backdrop"
  >
    <div class="modal-dialog modal_lw">
      <span aria-hidden="true" class="close" v-on:click="closePopup()"
        >&times;</span
      >
      <div class="panel panel-login">
        <div class="panel-heading">
          <div class="row">
            <div class="submit-heading">Submit Word to our database</div>
            <div
              v-show="errorMsg != '' || successMsg != ''"
              :class="successMsg != '' ? 'valid-feedback' : 'invalid-feedback'"
            >
              {{ successMsg != "" ? successMsg : errorMsg }}
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-lg-12">
              <div>
                <form
                  @submit.prevent="handleSuggestSubmit"
                  role="form"
                  v-bind:style="
                    suggestCalled == 'suggest-word'
                      ? 'display: block'
                      : 'display: none'
                  "
                >
                  <div class="form-group text-center">
                    <input
                      type="text"
                      v-model="suggestedWord"
                      name="suggestedWord"
                      tabindex="1"
                      class="form-control"
                      placeholder="Suggest a word"
                    />
                  </div>
                  <div class="form-group text-center">
                    <input
                      type="text"
                      v-model="definition"
                      name="definition"
                      tabindex="2"
                      class="form-control"
                      placeholder="Definition (optional)"
                    />
                  </div>
                  <div class="form-group text-center">
                    <div class="row">
                      <div class="col-sm-6 col-sm-offset-3">
                        <button
                          type="submit"
                          name="submit_word"
                          tabindex="4"
                          class="form-control btn submit_word btn-primary"
                        >
                          {{
                            submitted === true ? "Submitting..." : "Submit Word"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SuggestWord",
  data: () => {
    return {
      siteUrl: "https://fun.littlewordsmiths.com/API/",
      suggestCalled: "",
      suggestedWord: "",
      definition: "",
      errorMsg: "",
      successMsg: "",
    };
  },
  mounted() {
    this.suggestCalled = this.suggestionCalled;
  },
  props: ["suggestionCalled"],
  methods: {
    handleSuggestSubmit(e) {
      console.log(e);
      this.successMsg = "";
      this.errorMsg = "";
      this.submitted = true;
      const { suggestedWord, definition } = this;
      if (suggestedWord) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            newword: suggestedWord,
            definition: definition,
          }),
        };
        fetch(this.siteUrl + "ajax_suggestions.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.success == 1) {
              this.successMsg = data.message;
              this.suggestedWord = "";
              this.definition = "";
              this.submitted = false;
            } else {
              this.errorMsg = data.message;
              this.submitted = false;
            }
          });
      } else {
        this.errorMsg = "Please enter a word to suggest";
        this.submitted = false;
      }
    },
    closePopup: function () {
      this.$emit("childToParent", { type: "suggest-close", value: "" });
    },
  },
};
</script>